import Web3 from 'web3';

interface MoneyProps {
  ethers: boolean;
  money: string;
}

const Money: React.FC<{ money: string; tip: string; type: string, decimals?:number, ethers?: boolean }> = ({
  money,
  tip,
  decimals=2,
  ethers = true
}) => {

  const formatMoney = ({ ethers, money }: MoneyProps): string => {
    let amount;
    if (ethers) {
      try {
        amount = parseFloat(Web3.utils.fromWei(money, 'ether'));
      } catch (error) {
        console.error("Invalid value for Web3.utils.fromWei:", money);
        amount = parseFloat(money);
      }
    } else {
      amount = parseFloat(money);
    }
    return amount.toFixed(decimals); // Change to 6 if needed
  };

  const props: MoneyProps = { ethers, money };
  const formattedMoney = formatMoney(props);

  return (
    <div>
      <div>
        <div>
          <span className="bold pr-1">{tip}</span>
          {formattedMoney}
        </div>
      </div>
    </div>
  );
};

export default Money;