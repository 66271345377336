import React, { useState } from 'react';
import Web3 from 'web3';
import { AlertCircle, Check, Wallet } from 'lucide-react';

const CustomAlert: React.FC<{ variant: 'error' | 'success'; icon: React.ComponentType<{ className?: string }>; children: React.ReactNode }> = ({ variant, icon: Icon, children }) => (
  <div className={`flex items-center p-4 rounded-md ${
    variant === 'error' 
      ? 'bg-red-50 text-red-700 border border-red-200' 
      : 'bg-green-50 text-green-700 border border-green-200'
  }`}>
    {Icon && <Icon className="h-4 w-4 mr-2" />}
    <span>{children}</span>
  </div>
);

const WalletRegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [ethereumAddress, setEthereumAddress] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const connectWallet = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      if (!window.ethereum) {
        throw new Error('Please install MetaMask to continue');
      }

      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      
      // Create a unique message to sign
      const message = `Verify Ethereum account ownership for ${email} at ${new Date().toISOString()}`;
      
      // Sign the message
      const signature = await web3.eth.personal.sign(
        web3.utils.utf8ToHex(message),
        address,
        '' // Password is not needed for MetaMask
      );
      
      // Recover address from signature
      const recoveredAddress = await web3.eth.personal.ecRecover(
        web3.utils.utf8ToHex(message),
        signature
      );
      
      if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
        setEthereumAddress(address);
        setStatus('Wallet connected successfully');
        setIsSuccess(true);
        setError('');
      } else {
        throw new Error('Signature verification failed');
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

interface FormEvent extends React.FormEvent<HTMLFormElement> {}

interface ApiResponse {
    ok: boolean;
}

const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    try {
        const web3 = new Web3();
        if (!web3.utils.isAddress(ethereumAddress)) {
            throw new Error('Invalid Ethereum address');
        }

        // Your API call to register the wallet
        const response: ApiResponse = await fetch('/api/register-wallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                ethereumAddress,
                timestamp: new Date().toISOString()
            })
        });

        if (!response.ok) {
            throw new Error('Failed to register wallet');
        }

        setStatus('Wallet registered successfully');
        setIsSuccess(true);
    } catch (err) {
        if (err instanceof Error) {
            setError(err.message);
        } else {
            setError('An unknown error occurred');
        }
        setIsSuccess(false);
    } finally {
        setIsLoading(false);
    }
};

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">
        Register Ethereum Wallet
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
            placeholder="your@email.com"
          />
        </div>

        <div>
          <label htmlFor="ethereum" className="block text-sm font-medium text-gray-700 mb-1">
            Ethereum Address
          </label>
          <div className="flex gap-2">
            <input
              id="ethereum"
              type="text"
              value={ethereumAddress}
              onChange={(e) => setEthereumAddress(e.target.value)}
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="0x..."
            />
            <button
              type="button"
              onClick={connectWallet}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              <Wallet className="w-4 h-4 mr-2" />
              {isLoading ? 'Connecting...' : 'Connect'}
            </button>
          </div>
        </div>

        {error && (
          <CustomAlert variant="error" icon={AlertCircle}>
            {error}
          </CustomAlert>
        )}

        {isSuccess && (
          <CustomAlert variant="success" icon={Check}>
            {status}
          </CustomAlert>
        )}

        <button
          type="submit"
          disabled={isLoading || !email || !ethereumAddress}
          className="w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? 'Registering...' : 'Register Wallet'}
        </button>
      </form>
    </div>
  );
};

export default WalletRegistrationForm;