import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlockAddress } from "../commons/BlockAddress";
import PoolStake from "./PoolStake";
import { PoolDto } from "../../services/pools/poolTypes";
import Money from "../commons/Money";
import { UserState } from "../../services/users/userSlice";
import { CompactCustomProductDisplay, CompactProductDisplay } from "../stripecheckout/CustomPrices";


const MetricCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: string;
}> = ({ icon, label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
    <div className="flex items-center justify-between">
      <div className="flex-shrink-0 text-blue-600">
        {icon}
      </div>
      <p className="text-sm font-medium text-gray-500 ml-auto text-rigth">
          {label}
        </p>
     </div>
     <div className="flex">
        <p className="text-lg font-semibold text-gray-900 truncate ml-auto">
          {<Money type="CUSD" money={value} tip="" />}
        </p>
      </div>
  </div>
);

const StakeSection: React.FC<{
  apy: number | string;
  userState: UserState;
  poolAddress: string;
}> = ({ apy, userState, poolAddress }) => (
  <div className="lg:col-span-1 bg-gray-50 p-6 rounded-xl">
    <div className="text-center">
      <div className="text-3xl font-bold text-blue-600 mb-2">
        {apy || '15'}% APR
      </div>
      <p className="text-sm text-gray-600 mb-4">
        Estimated Annual Returns
      </p>
      
      <CompactProductDisplay
        logged={userState?.user?.id ? true : false}
        poolAddress={poolAddress}
        userId={userState?.user?.id}
        successUrl="/success"
        cancelUrl="/cancel"
      />
    </div>
  </div>
);

const Pool: React.FC<{
  pool: PoolDto;
  poolContract: any;
  userState: UserState;
}> = ({ pool, poolContract, userState }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden transition-all hover:shadow-md mt-8 m-2">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 p-6">
        {/* Header Section */}
        <div className="lg:col-span-3">
          <div className="flex flex-wrap justify-between items-start gap-4 mb-6">
            <div>
              <Link 
                to={`/pool/${pool.pool}`}
                className="inline-flex items-center space-x-2 group"
              >
                <h3 className="text-2xl font-bold text-gray-900 group-hover:text-blue-600">
                  {pool.name}
                </h3>
              </Link>
              <div className="mt-2 flex flex-col gap-4">
                <span className="text-sm px-2.5 py-0.5 rounded-full bg-blue-50 text-blue-700 font-medium">
                  {pool?.brief || 'Investment Pool'}
                </span>
                <div className="ml-2">
                  <BlockAddress type="address" address={pool.pool}/>
                </div>
              </div>
            </div>
            
            <Link to={`/pool/${pool.pool}`}>
              <button className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                  </svg>
                View Car Loans
              </button>
            </Link>
          </div>

          <p className="text-gray-600 mb-6">
            {pool.brief}
          </p>

          {/* Metrics Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <MetricCard
              icon={
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              }
              label="Ever Staked"
              value={pool.staked}
            />
            <MetricCard
              icon={
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2z" />
                </svg>
              }
              label="Interests"
              value={pool.interestsCollected}
            />
            <MetricCard
              icon={
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              }
              label="Remaining"
              value={pool.principalRemaining}
            />
            <MetricCard
              icon={
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              }
              label="Pool Cash"
              value={pool.cash}
            />
          </div>
        </div>

        {/* Stake Section */}
        <div className="lg:col-span-1 bg-blue-50 p-6 rounded-xl">
          <div className="text-center">
            <div className="text-3xl font-bold text-blue-600 mb-2">
              {typeof pool.rateNumerator === 'number' && typeof pool.rateDenominator === 'number' && pool.rateDenominator !== 0 
                ? (pool.rateNumerator / pool.rateDenominator).toFixed(2) 
                : '15'}% APR
            </div>
            <p className="text-sm text-gray-600 mb-6">
              Estimated Annual Returns
            </p>
            <CompactCustomProductDisplay
              logged={userState?.user?.id ? true : false}
              poolAddress={pool.pool}
              userId={userState?.user?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default Pool;
