import React from 'react';

type MetricCardProps = {
  icon: string;
  value: string;
  label: string;
};

const MetricCard: React.FC<MetricCardProps> = ({ icon, value, label }) => (
  <div className="text-center p-4">
    <img 
      src={icon} 
      alt={`${label} icon`} 
      className="w-24 h-24 mx-auto mb-4"
    />
    <h5 className="text-blue-600 text-2xl font-bold mb-1">
      {value}
    </h5>
    <p className="text-gray-800 text-lg">
      {label}
    </p>
  </div>
);

const InvestmentSection = () => {
  const metrics = [
    {
      icon: "/img/icons/01.png",
      value: "14,95% APY",
      label: "Total Return"
    },
    {
      icon: "/img/icons/02.png",
      value: "100 USD",
      label: "Min. Investment"
    },
    {
      icon: "/img/icons/03.png",
      value: "Liquid",
      label: "Holding time"
    },
    {
      icon: "/img/icons/04.png",
      value: "Daily",
      label: "Payout"
    },
    {
      icon: "/img/icons/05.png",
      value: "CUSD",
      label: "Payment type"
    }
  ];

  return (
    <section className="bg-gray-100 py-16 md:py-20">
      <div className="container mx-auto px-4">
        <h3 className="text-center text-2xl md:text-3xl font-bold text-gray-900 mb-12">
          Invest in car loans directly on the Polygon Smart Chain for as little as $100
        </h3>

        <div className="flex flex-wrap md:flex-nowrap justify-around items-center gap-8">
          {metrics.map((metric, index) => (
            <MetricCard
              key={index}
              icon={metric.icon}
              value={metric.value}
              label={metric.label}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InvestmentSection;