// Assuming you are using React
import React, { useState } from "react";
import axios from "axios";

const VerificationButton: React.FC = () => {
  const startVerification = async () => {
    try {
      const response = await fetch("/api/stripe/start-verification", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      const data = await response.json();
      const popup = window.open(data.url, "_blank");
      
      if (popup) {
        const checkPopupClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkPopupClosed);
            // Handle popup closed event
          }
        }, 1000);
      }
    } catch (error) {
      console.error("Error starting verification:", error);
    }
  };

  return (
    <button
      onClick={startVerification}
      className="mt-3 w-full rounded-lg bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition-colors hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
    >
      Start Verification
    </button>
  );
};

export default VerificationButton;
