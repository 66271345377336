import { CarLoanContract, CarLoanContractFull } from "../../services/cars/carTypes";
import {
    BlockAddress,
    BlockMoneyInLine,
    TXAddress,
    BlockCalendar,
    Progress,
  } from "../commons/BlockAddress";
  import { Link, useParams } from "react-router-dom";
import BlockMoney from "../commons/BlockMoney";
import BlockData from "../commons/BlockData";
import BlockRate from "../commons/BlockRate";
import BlockBoolean from "../commons/BlockBoolean";

// Individual address card component for better reusability
const AddressCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  address: string;
  type: string;
}> = ({ icon, label, address, type }) => {
  const shortenAddress = (address: string) => {
    if (address.length <= 20) return address;
    return `${address.slice(0, 10)}...${address.slice(-10)}`;
  };

  return (
    <div className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0 text-gray-500">
          {icon}
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm font-medium text-gray-900">
            {label}
          </p>
          <a
            href={`https://oklink.com/es-la/amoy/${type}/${address}`}
            target="_blank"
            rel="noreferrer"
            className="text-sm text-gray-500 hover:text-blue-600 transition-colors"
          >
            <span className="hidden md:block">{address}</span>
            <span className="md:hidden">{shortenAddress(address)}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

// Icons for different address types
const AddressIcons = {
  loan: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
    </svg>
  ),
  pool: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
    </svg>
  ),
  borrower: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
    </svg>
  ),
  collector: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  )
};

export const CarLoanBlockchainAddress: React.FC<{
  carLoanContract: CarLoanContractFull
}> = ({ carLoanContract }) => {
  const addresses = [
    { 
      type: 'type',
      label: 'Loan Address',
      address: carLoanContract.carloan,
      icon: AddressIcons.loan 
    },
    { 
      type: 'type',
      label: 'Pool Address',
      address: carLoanContract.pool,
      icon: AddressIcons.pool 
    },
    { 
      type: 'type',
      label: 'Borrower Address',
      address: carLoanContract.borrower,
      icon: AddressIcons.borrower 
    },
    { 
      type: 'type',
      label: 'Collector Address',
      address: carLoanContract.collector,
      icon: AddressIcons.collector 
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">
        Contract Addresses
      </h3>
      <div className="grid gap-4">
        {addresses.map((addr, index) => (
          <AddressCard
            key={index}
            type={addr.type}
            label={addr.label}
            address={addr.address || "0x"}
            icon={addr.icon}
          />
        ))}
      </div>
    </div>
  );
};

const CarLoanBlockchain: React.FC<{ carLoanContract: CarLoanContractFull }> = ({ carLoanContract }) => {
  // Group related metrics for better organization
  const financialMetrics = [
    {
      component: BlockMoney,
      props: {
        type: "address",
        money: carLoanContract?.principalRemaining,
        tip: "Principal Remaining:"
      }
    },
    {
      component: BlockMoney,
      props: {
        type: "address",
        money: carLoanContract?.balanceRemaining,
        tip: "Remaining Balance:"
      }
    },
    {
      component: BlockMoney,
      props: {
        type: "interest",
        money: carLoanContract?.interestPaid,
        tip: "Interest Paid:"
      }
    },
    {
      component: BlockMoney,
      props: {
        type: "address",
        money: carLoanContract?.payment,
        tip: "Daily Payment:"
      }
    }
  ];

  const rateMetrics = [
    {
      component: BlockRate,
      props: {
        data: carLoanContract?.rateNumerator,
        tip: "Daily Rate:"
      }
    },
    {
      component: BlockRate,
      props: {
        data: carLoanContract?.rateNumerator,
        tip: "Monthly Rate:",
        base: 30
      }
    },
    {
      component: BlockRate,
      props: {
        data: carLoanContract?.rateNumerator,
        tip: "Yearly Rate:",
        base: 365
      }
    }
  ];

  return (
    <div className="rounded-xl shadow-sm p-6 mt-6">
      <h3 className="text-xl font-bold text-gray-900 mb-6">
        Contract Metrics
      </h3>

      <div className="space-y-8">
        {/* Financial Metrics Section */}
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-4">
            Financial Information
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {financialMetrics.map((metric, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors">
                <metric.component {...metric.props} />
              </div>
            ))}
          </div>
        </div>

        {/* Rate Metrics Section */}
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-4">
            Rate Information
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {rateMetrics.map((metric, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors">
                <metric.component {...metric.props} />
              </div>
            ))}
          </div>
        </div>

        {/* Additional Information */}
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-4">
            Additional Details
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors">
              <BlockData 
                data={carLoanContract?.paymentsMade} 
                tip="Payments Made:" 
              />
            </div>
            {carLoanContract.status && (
              <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors">
                <BlockBoolean 
                  data={carLoanContract?.status} 
                  tip="Status:" 
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarLoanBlockchain