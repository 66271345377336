import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory

import DemoLegend from "../experience/demo"
import SideBar from "../experience/SideBar"
import WalletRegistrationForm from "./ValidateAddress"


const UserCollect = () => { 

    const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="bg-white min-h-screen">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>

        <div className="flex flex-col justify-start items-center mt-10 mx-2">
            <DemoLegend />
            <WalletRegistrationForm />
        </div>
    </div>
)
}

export default UserCollect;