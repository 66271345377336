import React from 'react';

const BenefitCard: React.FC<{ children: string[], bgColor?: string }> = ({ children, bgColor = 'bg-blue-100' }) => (
  <div className={`${bgColor} rounded-2xl shadow-lg p-6 h-full`}>
    <div className="space-y-4">
      {children.map((benefit, index) => (
        <p key={index} className="flex items-center text-gray-700">
          <img src="/img/icn_check_02.png" alt="check" className="w-4 h-4 mr-2" />
          {benefit}
        </p>
      ))}
    </div>
  </div>
);

const BenefitsSection = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-blue-600 text-center mb-16">
          Benefits
        </h2>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <BenefitCard bgColor="bg-blue-100">
            {[
              'Get the best apy-risk ratio in the market',
              'Invest in a crypto stable and liquid market',
              'Get Daily return on investments'
            ]}
          </BenefitCard>

          <BenefitCard bgColor="bg-white">
            {[
              'Operate in 24x7, maximizing revenue',
              'Access detailed data of their collateralized investments',
              'Ensure assets are kept secure in a blockchain database',
              'Find a single marketplace for retail and institutional investors'
            ]}
          </BenefitCard>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;