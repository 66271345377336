import React from 'react';

type FeatureCardProps = {
  iconSrc: string;
  title: string;
  description: string;
};

const BackgroundCurve = () => (
  <div className="absolute top-0 left-0 w-full h-48 overflow-hidden">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#4463cd', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#4463cd', stopOpacity: 1 }} />
            </linearGradient>
        </defs>
        <path d="M0,200 L0,80 C360,30 720,30 1080,80 L1440,80 L1440,200 L0,200" fill="url(#gradient)"/>
        </svg>
  </div>
);

const FeatureCard: React.FC<FeatureCardProps> = ({ iconSrc, title, description }) => (
  <div className="text-center mb-8">
    <figure className="inline-block">
      <img src={iconSrc} alt={title} className="mx-auto w-24 h-24 mb-4" />
      <h5 className="text-white text-xl font-bold mb-2">{title}</h5>
      <figcaption className="text-white text-sm leading-relaxed whitespace-pre-line">
        {description}
      </figcaption>
    </figure>
  </div>
);

const WhyCarDefier = () => {
  const features = [
    {
      iconSrc: "/img/why/icn_why_01.png",
      title: "Higher profits",
      description: "Revenue on a daily basis\nlets you reinvest and\ncompound interests for\na higher yield"
    },
    {
      iconSrc: "/img/why/icn_why_02.png",
      title: "Saves Time",
      description: "Invest and take profits,\nwith few clicks from\nyour wallet"
    },
    {
      iconSrc: "/img/why/icn_why_03.png",
      title: "Reduces Risk",
      description: "Tampering, fraud\n& cyber crime"
    },
    {
      iconSrc: "/img/why/icn_why_04.png",
      title: "Increases trust",
      description: "Through shared\nprocesses and\nrecordkeeping"
    },
    {
      iconSrc: "/img/why/icn_why_05.png",
      title: "Leverages Industry Experience",
      description: "Car DeFier team\naccounts for more than\n10 years of experience on\nthe Car Loan Industry"
    }
  ];

  return (
    <section className="bg-[#4463cd] py-20 overflow-hidden relative" id="why">
      <BackgroundCurve />
      <div className="container mx-auto px-4 relative z-10">
        {/* Title */}
        <h2 className="text-4xl font-bold text-white text-center mb-16">
          Why Car Defier
        </h2>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mb-16">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        {/* Risk Management Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-16">
          <div className="order-2 md:order-1">
            <div className="text-left text-white">
              <h4 className="text-2xl font-bold mb-6">We take care of risk:</h4>
              <div className="space-y-6">
                <p className="leading-relaxed">
                  Car Defier collateralizes car-loans to generate pools to match investors profile-risk. 
                  This pools and risk portfolio are fully managed by portfolio managers.
                </p>
                <div>
                  <strong className="block mb-2">Underwriting risk:</strong>
                  <p>Credit pools come from a Car Dealer with 10 years of experience in the market, 
                  and a good track record of: yield, origination and customer experience.</p>
                </div>
                <div>
                  <strong className="block mb-2">Counterparty Risk:</strong>
                  <p>AutoLoans are secured by using the car as collateral for the loan. 
                  Credit Pool Risk profile is secured by Line Capital.</p>
                </div>
                <div>
                  <strong className="block mb-2">Colateral risk:</strong>
                  <p>During the lifetime of the loan the car is fully insured to protect recovery value.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 md:order-2">
            <figure className="text-center">
              <img
                src="/img/why-car.png"
                alt="We take care of risk"
                className="max-w-full inline-block"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyCarDefier;