import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from '../../context/auth-context';
import { Car, CarLoan, CarLoanContractFull } from '../../services/cars/carTypes';
import { BlockAddress, BlockCalendar } from '../commons/BlockAddress';
import BlockMoney from '../commons/BlockMoney';
import BlockData from '../commons/BlockData';
import BlockRate from '../commons/BlockRate';
import BlockBoolean from '../commons/BlockBoolean';
import { CompactProductDisplay } from '../stripecheckout/CustomPrices';

// Car Details Component
const CarDetails: React.FC<{ car: Car }> = ({ car }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="relative h-64 overflow-hidden">
        <img 
          src={car?.images[0]} 
          alt={`${car.brand} ${car.model}`}
          className="w-full h-full object-cover"
        />
      </div>

      
      
      <div className="p-6">
        <div className="flex justify-between items-start mb-6">
          <h3 className="text-2xl font-bold text-gray-900">
            {`${car.brand} ${car.model} ${car.year}`}
          </h3>
          <div className="flex items-center space-x-2">
            <div className="h-2.5 w-2.5 rounded-full bg-emerald-500" />
            <span className="text-sm text-gray-500">Performing</span>
          </div>
        </div>

      <AboutTheCar car={car} />

      </div>
    </div>
  );
};

// Car Loan Blockchain Component
const CarLoanBlockchain: React.FC<{ carLoanContract: CarLoanContractFull }> = ({ carLoanContract }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mt-6">
      <h3 className="text-xl font-bold text-gray-900 mb-6">
        Contract Details
      </h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockAddress type="type" address={carLoanContract.carloan + " Loan Address"} />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockAddress type="type" address={carLoanContract.pool + " Pool Address"} />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockMoney type="address" money={carLoanContract?.principalRemaining} tip="Principal Remaining:" />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockRate data={carLoanContract?.rateNumerator} tip="Daily Rate:" />
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockMoney type="address" money={carLoanContract?.balanceRemaining} tip="Remaining Balance:" />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockMoney type="interest" money={carLoanContract?.interestPaid} tip="Interest Paid:" />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <BlockCalendar date={carLoanContract?.initDate} tip="Init Date:" />
          </div>
          {carLoanContract.status && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <BlockBoolean data={carLoanContract?.status} tip="Status:" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Car Loan Details Component
const CarLoanDetails: React.FC<{ carLoan: CarLoan }> = ({ carLoan }) => {
  return (
    <div className="rounded-xl shadow-sm p-6 mt-6">
      <h3 className="text-xl font-bold text-gray-900 mb-6">
        Original Conditions
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={carLoan?.principal} tip="Principal:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={carLoan?.remainingBalance} tip="Remaining Balance:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={carLoan?.payment} tip="Payment:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={carLoan?.gracePeriod} tip="Grace Period:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={carLoan?.rate} tip="Rate:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockBoolean data={carLoan?.status} tip="Status:" />
        </div>
      </div>
    </div>
  );
};


// Car Loan Details Component
export const AboutTheCar: React.FC<{ car: Car }> = ({ car }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mt-6">
      <h3 className="text-xl font-bold text-gray-900 mb-6">
        About the car
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={car?.uuid} tip="Identifier:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={car?.ownerFico} tip="Oner Fico:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={car?.kilometers} tip="Kilometers:" />
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <BlockData data={car?.marketValue} tip="Market Value:" />
        </div>
      </div>
    </div>
  );
};

export { CarDetails, CarLoanBlockchain, CarLoanDetails };