import { Car } from "../../services/cars/carTypes";

import BlockData from "../commons/BlockData";

const CarDetails : React.FC<{car: Car}> = ({car}) => {

    return (
            <div className="px-4">
                <div className="p-4 lg:flex-auto">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 mb-5">
                        {`${car.brand} ${car.model} ${car.year}`}
                            <div className="flex flex-row">
                                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                <div className="h-2.5 w-2.5 rounded-full bg-emerald-500"></div>
                                </div>
                                <p className="text-xs leading-5 text-gray-500">
                                Performing
                                </p>
                            </div>

                    </h3>
                    <img src={car?.images[0]} className="my-2" alt="Car"></img>

                </div>
            </div>
    )

}

export default CarDetails