import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Web3 from "web3";
import { useNavigate } from 'react-router-dom';

interface StakeBarChartProps {
  stake: string;
  gains: string;
  pool: string;
}

const StakeBarChart: React.FC<StakeBarChartProps> = ({ stake, gains, pool }) => {

  const navigate = useNavigate();

  const web3 = new Web3();


  const stakeValue = parseFloat(web3.utils.fromWei(stake, "ether"));
  const gainsValue = parseFloat(web3.utils.fromWei(gains, "ether"))
  const totalValue = stakeValue + gainsValue;

  const percentageGain = ((gainsValue / stakeValue) * 100).toFixed(2);

  const series = [{
    name: 'Stake',
    data: [stakeValue]
  }, {
    name: 'Gains',
    data: [gainsValue]
  }];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
      },
    },
    colors: ['#82ca9d', '#8884d8'],
    xaxis: {
      categories: [pool],
      labels: {
        style: {
          colors: '#666'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return value.toFixed(2);
        }
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2);
        }
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  return (
    <div className="rounded-2xl p-4 text-left bg-gray-50 ring-1 ring-inset ring-gray-900/5 lg:flex lg:justify-center mb-8 flex md:flex-row flex-col">
    <div className="flex flex-row w-full items-center justify-between px-4">
      <ReactApexChart 
        options={options}
        series={series}
        type="bar"
        height={200}
      />

      <div className="text-center text-sm flex flex-col">
        <span className="font-medium text-gray-500 bold">Investment on {pool} pool</span>
        <>
          <span className="font-medium text-gray-900">CUSD {(totalValue).toFixed(2)}</span>
          <span className="ml-2 text-green-600">(+{percentageGain}%)</span>
        </>
      </div>

    </div>
    </div>
  );
};

export default StakeBarChart;