import React from 'react';

const FooterLink: React.FC<{ href: string; children: React.ReactNode }> = ({ href, children }) => (
  <a 
    href={href} 
    className="text-white hover:text-gray-300 px-4 border-l border-white first:border-0"
  >
    {children}
  </a>
);

const Footer = () => {

  return (
    <div className="bg-white flex flex-col">
      {/* Footer */}
      <footer className="bg-gray-800">
        {/* Main Footer */}
        <div className="container mx-auto px-4 pt-12">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            {/* Logo Column */}
            <div className="md:col-span-3">
              <a href="/" className="block">
                <img 
                  src="/img/car-defier-footer.png" 
                  alt="Car Defier" 
                  className="w-40"
                />
              </a>
            </div>
            
            {/* Navigation Column */}
            <nav className="md:col-span-7">
              <div className="flex flex-col md:flex-row md:items-center justify-center space-y-4 md:space-y-0">
                <FooterLink href="/vision">Vision</FooterLink>
                <FooterLink href="#why">Why Car Defier</FooterLink>
                <FooterLink href="/benefits">Benefits</FooterLink>
                <FooterLink href="/roadmap">Roadmap</FooterLink>
                <FooterLink href="/partners">Partners</FooterLink>
                <FooterLink href="/contact">Contact</FooterLink>
              </div>
            </nav>
          </div>

          {/* Copyright */}
          <div className="mt-10 py-4 border-t border-gray-700 text-center">
            <p className="text-white text-sm">© 2024 Car Defier</p>
          </div>
        </div>

        {/* Legal Section */}
        <div className="bg-white mt-8 py-8">
          <div className="container mx-auto px-4">
            <p className="text-sm text-gray-600 leading-relaxed">
              This site is operated by Car Defier LLC, which is not a registered broker-dealer or investment advisor. 
              Car Defier LLC does not give investment advice, endorsement, analysis or recommendations with respect to any securities. 
              Nothing on this website should be construed as an offer to sell, solicitation of an offer to buy or a recommendation 
              for any security by Car Defier LLC or any third party. This information is not an offer to invest in any token, 
              fund or other opportunity and is provided for information only. Nothing in this website shall be deemed to constitute 
              an offer, offer to sell, or the solicitation of an offer to buy, any securities in any U.S. jurisdiction.
              <span className="block mt-4">
                Residents of the following countries are not permitted to invest in Car Defier: United States of America, 
                Afghanistan, Belarus, Bosnia and Herzegovina, Central African Republic, China, Congo, Guinea, Iran, Iraq, 
                Libya, North Korea, Somalia, Yemen, Zimbabwe.
              </span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;