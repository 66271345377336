
const HeroSection = () => (
  <div className="bg-gradient-to-b from-blue-600 to-blue-500 pt-20">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center py-16">
        {/* Text Content */}
        <div className="text-center lg:text-left">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Car Lending on DeFi roads
          </h1>
          <p className="text-xl text-blue-100 mb-8">
            Stake on our Car Lending Pools and get up to 15% APR.
          </p>
        </div>
        
        {/* Illustration */}
        {/* <div className="relative">
          <img 
            src="/img/hero-illustration.svg" 
            alt="Car DeFi Illustration" 
            className="w-full max-w-2xl mx-auto"
          />
        </div> */}
      </div>
    </div>
    
    {/* Wave Separator */}
    <div className="w-full">
      <svg viewBox="0 0 1440 100" className="w-full" preserveAspectRatio="none">
        <path 
          fill="#fff"
          d="M0,32L60,37.3C120,43,240,53,360,58.7C480,64,600,64,720,58.7C840,53,960,43,1080,42.7C1200,43,1320,53,1380,58.7L1440,64L1440,100L1380,100C1320,100,1200,100,1080,100C960,100,840,100,720,100C600,100,480,100,360,100C240,100,120,100,60,100L0,100Z"
        />
      </svg>
    </div>
  </div>
);

export default HeroSection;