import React, { useEffect, useState } from "react";
import axios from "axios";
import { BlockAddress, BlockCalendar, BlockMoneyInLine, TXAddress } from "../commons/BlockAddress";
import LoadingSpinner from "../../components/elements/loading-spinner";
import Money from "../commons/Money";
import BlockMoney from "../commons/BlockMoney";
import { cancelPaymentRest } from "../../services/stripe/stripeRest";
import classNames from 'classnames';
import WalletRegistrationForm from "./ValidateAddress";

const getStatusClasses = (status: string) => {
  switch (status) {
    case 'SUCCEEDED':
      return 'bg-green-50 text-green-700 ring-green-600/20';
    case 'PROCESSING':
    case 'CREATED':
      return 'bg-orange-50 text-orange-700 ring-orange-600/20';
    case 'FAILED':
    case 'CANCELED':
    case 'REFUNDED':
    case 'PARTIALLY_REFUNDED':
    case 'DISPUTED':
    case 'DISPUTE_WON':
    case 'DISPUTE_LOST':
      return 'bg-red-50 text-red-700 ring-red-600/20';
    default:
      return 'bg-gray-50 text-gray-700 ring-gray-600/20';
  }
};

function UserPaymentIntents() {
  const [paymentIntents, setPaymentIntents] = useState([]);
  const [visibleIntents, setVisibleIntents] = useState(5); // Initial visible intents
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPaymentIntents = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/users/payments", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Ensure you're using correct auth headers
          },
        });
        setPaymentIntents(response.data);
        setLoading(false);
      } catch (err) {
        setError("Could not find payments information");
        setLoading(false);
      }
    };

    fetchPaymentIntents();
  }, []);

  const showMore = () => {
    setVisibleIntents(paymentIntents.length); // Show all records
  };

  const showLess = () => {
    setVisibleIntents(5); // Reset to initial state
  };

  const cancelPayment = async(paymentIntentId: string) => {
      cancelPaymentRest({paymentIntentId}).then(()=>{
        alert("cancel request received")
      }).catch((error)=>{
        alert(error)
      })
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  if (loading) return <LoadingSpinner/>

  return (
    <div className="rounded-2xl p-4 text-left m bg-gray-50  ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center mb-8">
      <h4 className="text-1xl font-bold tracking-tight text-indigo-900">
        Your Stakes
      </h4>

      {error && <p className="tex-red-800">{error}</p>}
      <div>
        {paymentIntents.slice(0, visibleIntents).map((intent: any) => (
              <div className="flex flex-col text-green-700 p-5 ring-1 ring-inset ring-green-600/20 my-4 mx-2">
               
              <BlockCalendar tip={""} date={intent.createdAt} />    
              
              <BlockMoneyInLine
                    type={"wei"}
                    money={String(intent.amount / 100)  }
                    tip={"Staked:"}
                    ethers={false}
                  />

            {(intent.txhash && intent.txhash.length) > 0 && (
                <TXAddress type="tx" tx={intent.txhash} />
              )}

                <span className={classNames('my-1 inline-flex items-center rounded-md p-2 py-1 text-xs ring-1 ring-inset', getStatusClasses(intent.status))}>
                  {intent.status}
                </span>

                {/* {intent.status === "SUCCESSFULL" &&
                  <button onClick={()=>{
                    cancelPayment(intent.id)
                  }}>Cancel now</button>
                } */}

             
              </div>
        ))}
      </div>
      {visibleIntents < paymentIntents.length && (
        <button
          onClick={showMore}
          className="flex ml-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-10"
        >
          Show More
        </button>
      )}
      {visibleIntents > 5 && (
        <button
          onClick={showLess}
          className="flex ml-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Show Less
        </button>
      )}
    </div>
  );
}

export default UserPaymentIntents;
