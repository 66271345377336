import React from 'react';

type BulletPointProps = {
  children: React.ReactNode;
};

const BulletPoint: React.FC<BulletPointProps> = ({ children }) => (
  <div className="flex items-start space-x-2 mb-4">
    <img 
      src="/img/icn_check_02.png" 
      alt="bullet" 
      className="w-4 h-4 mt-1 flex-shrink-0"
    />
    <p className="text-white leading-relaxed">
      {children}
    </p>
  </div>
);

const CarLoanEvolution = () => {
  return (
    <section className="bg-[#4463cd] ">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Image Column */}
          <div className="relative h-full flex items-center justify-center">
            <div className="w-full">
                <img
                src="/img/car-loan-ev.png"
                alt="Car Loan Evolution"
                className="w-full h-auto"
                />
            </div>
            </div>

          {/* Content Column */}
          <div className="py-16 px-4 md:px-8 flex items-center">
            <div className="max-w-xl">
              <h4 className="text-2xl font-bold text-gray-900 mb-6">
                Car Loan Evolution
              </h4>

              <div className="space-y-6">
                <p className="text-white leading-relaxed">
                  <strong>Car Defier</strong> is the evolution of the traditional car lending industry,
                  on the grounds of the crypto worlds.
                </p>

                <p className="text-white leading-relaxed">
                  Crypto loans are collateralized in smart contracts to be bought and sold
                  in liquid decentralized markets.
                </p>

                <div className="mt-6">
                  <p className="text-white mb-4">
                    Without the influence and intervention of traditional CeFi institutions
                    (Banks, Brokers):
                  </p>

                  <div className="space-y-4">
                    <BulletPoint>
                      Borrowers can finance their cars in a democratic and fair environment
                    </BulletPoint>
                    
                    <BulletPoint>
                      Investor get the best, accountable and transparent APY-RISK on the blockchain
                    </BulletPoint>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarLoanEvolution;