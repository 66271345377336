import Web3 from 'web3';

const BlockMoney: React.FC<{ money: string; tip: string; type: string, ethers?: boolean }> = ({
    money,
    tip,
    ethers = true
  }) => {

    const decimalPlaces = 4; // Change this to 6 if needed
    const formattedMoney = ethers 
      ? parseFloat(Web3.utils.fromWei(money, 'ether')).toFixed(decimalPlaces) 
      : parseFloat(money).toFixed(decimalPlaces); 
    
    return (
      <div className="flex flex-col md:flex-row mt-3">
        <div className="flex flex-col md:flex-row gap-y-1">
          <div className="flex flex-row  mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <div>{tip}</div>
         </div>
              <div className="address-container  text-gray-800 text-md font-medium mx-1 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 rounded">
                {formattedMoney}
          </div>
        </div>
      </div>
    );
  };

  export default BlockMoney