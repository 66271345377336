// src/components/UserProfile.tsx

import React, { useEffect, useState } from "react";
import UserPaymentIntents from "./UserPaymentIntents";
import Login from "../auth/Login";
import SideBar from "../experience/SideBar";
import StartVerificationButton from "../../components/elements/StartVerificationButton";
import { useAuth } from "../../context/auth-context";
import { getUserPoolState } from "../../services/pools/poolRest";
import GradientDonutChart from "./GradientDonutChart";
import Footer from "../experience/footer";
import DemoLegend from "../experience/demo";
import LoadingSpinner from "../../components/elements/loading-spinner";
import StakeBarChart from "./StakeBarChart";
import UserStateCard from "./UserState";
import UserWallets from "./UserWallet";

function UserProfile() {

  const {logout} = useAuth();

  const [open, setOpen] = useState<boolean>(false);

  const { userState, refreshUserData } = useAuth(); // useContext to access auth context
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [goldStakes, setGoldStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)

  const [riskStakes, setRiskStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)


  useEffect(()=>{
    const getData = async () => { 

      const gold = process.env.REACT_APP_ADDRESS_PGOLD || '0x0'
      const risk = process.env.REACT_APP_ADDRESS_PRISK || '0x0'
  
      if (!userState?.user?.wallet || userState.user.wallet.length === 0) return console.error('No wallet address');
      const goldResponse = await getUserPoolState(gold, userState.user.wallet[0]);
      const riskResponse = await getUserPoolState(risk, userState.user.wallet[0]);
  
      setGoldStakes(goldResponse)
      setRiskStakes(riskResponse)
    };
    getData()
    refreshUserData()
  },[])

  if (!userState.user) return <Login />;
  if (loading) return <LoadingSpinner/>
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bg-white min-h-screen">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>

      <div className="flex flex-col justify-start items-center mt-10 mx-2">
        <DemoLegend />

        <div className={`mx-auto mt-5 md:mt-14 rounded-xl sm:mt-20 lg:flex w-full md:max-w-2xl`}>
          <div className="lg:flex-auto w-full">


          {userState.user && <UserStateCard logout={logout} userState={{ user: userState.user }} />}

          <UserWallets userId={userState.user.id} />


        {((goldStakes?.stake || riskStakes?.stake) &&  (goldStakes?.stake !== "0" || riskStakes?.stake !== "0")) &&
           <div className="mb-4">
           <h2 className="mb-4">
              <b>Your investment</b>
            </h2> 
            <div className="flex flex-col w-full ">
              <StakeBarChart stake={goldStakes?.stake || "0"} gains={goldStakes?.gains || "0"} pool={"Gold"}/>
              <StakeBarChart stake={riskStakes?.stake || "0"} gains={riskStakes?.gains || "0"} pool={"Fresh"}/>
            </div>
            </div>
          }

          {/* {((goldStakes?.stake || riskStakes?.stake) &&  (goldStakes?.stake !== "0" || riskStakes?.stake !== "0")) &&
            <div className="flex md:flex-row flex-col">
              <Unstake stake={goldStakes?.stake || "0"} gains={goldStakes?.gains || "0"} pool={"Gold"}/>
              <Unstake stake={riskStakes?.stake || "0"} gains={riskStakes?.gains || "0"} pool={"Fresh"}/>
            </div>
          } */}

          <UserPaymentIntents />

        {/* <div className="flex md:w-[800px] px-5">
          <UserStakes />
        </div> */}

          </div>
        </div>

      </div>
      <Footer />

    </div>
  );
}

export default UserProfile;
