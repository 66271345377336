import React from 'react';

const IntroSection = () => {
  return (
    <section className="bg-[#4463cd] py-10">
      <div className="container mx-auto px-4">
        
      <div className="flex flex-col items-center justify-center h-full">
      <h3 className="text-2xl text-white mb-8 text-center">
          A decentralized market place for car-loans:
        </h3>

        <p className="flex items-center text-white text-center">
          <img src="/img/icn_check.png" alt="check" className="w-4 h-4 mr-2" />
          That lets people buy a car by loans collateralized on blockchain lending pools
        </p>
        
        <p className="flex items-center text-white text-center mt-4">
          <img src="/img/icn_check.png" alt="check" className="w-4 h-4 mr-2" />
          Offers lending pools to investors who look for a stable revenue without facing volatility
        </p>
          
          <h3 className="text-xl font-bold text-white mt-6">
            Car Defier offers the best APY-RISK ratio on the crypto world.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;