import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BlockAddress } from "../commons/BlockAddress";
import LoadingSpinner from "../../components/elements/loading-spinner";

type Wallet = {
  id: string;
  address: string;
  source: string;
};

function UserWallets({ userId }: { userId: string }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('investment');
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [withdrawWallet, setWithdrawWallet] = useState<Wallet|undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        setLoading(true);
        const response = await fetch('/api/users/wallets', {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setWallets(data);
      } catch (err) {
        setError("Failed to fetch wallets");
      } finally {
        setLoading(false);
      }
    };
    fetchWallets();
  }, [userId]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="w-full rounded-2xl bg-gray-50 p-6 ring-1 ring-inset ring-gray-900/5">
      {error && (
        <div className="mb-4 rounded bg-red-50 p-3 text-sm text-red-600">
          {error}
        </div>
      )}
      
      {/* Tabs */}
      <div className="mb-6 flex space-x-1 rounded-lg bg-gray-200 p-1">
        <button
          onClick={() => setActiveTab('investment')}
          className={`flex-1 rounded-md px-3 py-2 text-sm font-medium ${
            activeTab === 'investment'
              ? 'bg-white text-indigo-600 shadow'
              : 'text-gray-700 hover:text-indigo-600'
          }`}
        >
          Investment Wallet
        </button>
        <button
          onClick={() => setActiveTab('withdraw')}
          className={`flex-1 rounded-md px-3 py-2 text-sm font-medium ${
            activeTab === 'withdraw'
              ? 'bg-white text-indigo-600 shadow'
              : 'text-gray-700 hover:text-indigo-600'
          }`}
        >
          Withdraw Wallet
        </button>
      </div>

      {/* Tab Content */}
      <div className="mt-4">
        {/* Investment Tab Content */}
        {activeTab === 'investment' && (
          <div className="space-y-4">
            <div>
              <h4 className="text-lg font-semibold text-indigo-900">
                Investment Wallet
              </h4>
              <p className="mb-4 text-sm text-gray-600">
                We handle your investment through this wallet. Click to get a proof of transactions made.
              </p>
              <div className="space-y-2">
                {wallets.map((wallet) => (
                  <div key={wallet.id} className="rounded-lg border border-gray-100 bg-white p-3">
                    <BlockAddress type="address" address={wallet.address} />
                  </div>
                ))}
                {wallets.length === 0 && (
                  <p className="italic text-sm text-gray-500">No investment wallets found.</p>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Withdraw Tab Content */}
        {activeTab === 'withdraw' && (
          <div className="space-y-4">
            <div>
              <h4 className="text-lg font-semibold text-indigo-900">
                Withdraw Wallet
              </h4>
              {withdrawWallet ? (
                <div className="rounded-lg border border-gray-100 bg-white p-3">
                  <BlockAddress type="address" address={withdrawWallet.address} />
                </div>
              ) : (
                <div className="space-y-4">
                  <p className="text-sm text-gray-600">
                    Please register a wallet to securely withdraw your funds. Remember you have to wait up to 60 days to make your first withdrawal.
                  </p>
                  <button
                    onClick={() => navigate("/user_collect")}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto"
                  >
                    Collect
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserWallets;