import React, { useState, useEffect } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import Stake, { CompactStake } from '../stake/stake';

type Product = {
  id: string;
  name: string;
  description: string;
  price: number;
  priceId: string;
};

const PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;



export const CompactProductDisplay: React.FC<{
  logged: boolean;
  successUrl: string;
  cancelUrl: string;
  userId?: string;
  poolAddress: string;
}> = ({ logged, successUrl, cancelUrl, userId, poolAddress }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<string>("");
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (poolAddress === process.env.REACT_APP_ADDRESS_PRISK) {
      setProducts([
        {
          id: "bronce",
          name: "Bronce",
          description: "Bronce Stake",
          price: 499.00,
          priceId: "price_1PdwtxL2vpc5cVKi4V26AqiN"
        },
        {
          id: "silver",
          name: "Silver",
          description: "Silver Stake",
          price: 999.00,
          priceId: "price_1PdwkEL2vpc5cVKiiYDcdq0d"
        },
        {
          id: "gold",
          name: "Gold",
          description: "Gold Stake",
          price: 25000.00,
          priceId: "price_1PdwklL2vpc5cVKijHKv5Vmv"
        },
      ]);
    }

    if (poolAddress === process.env.REACT_APP_ADDRESS_PGOLD) {
      setProducts([
        {
          id: "starter",
          name: "Starter",
          description: "Starter Stake",
          price: 249.99,
          priceId: "price_1PfZhyL2vpc5cVKiOOjk0BFN"
        },
        {
          id: "pro",
          name: "Pro",
          description: "Pro Stake",
          price: 749.99,
          priceId: "price_1PdwkEL2vpc5cVKiiYDcdq0d"
        },
        {
          id: "high",
          name: "high",
          description: "high Stakes",
          price: 19999.99,
          priceId: "price_1PfZhYL2vpc5cVKitoFaC9ip"
        },
      ]);
    }

    const initializeStripe = async () => {
      if (!PUBLISHABLE_KEY) return;
      const stripeInstance = await loadStripe(PUBLISHABLE_KEY);
      setStripe(stripeInstance);
    };
    initializeStripe();
  }, [poolAddress]);

  const handleProductSelect = (priceId: string) => {
    setSelectedPrice(priceId);
  };

  const handleCheckout = async () => {
    if (!stripe || !selectedPrice) return;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: selectedPrice, quantity: 1 }],
      mode: 'payment',
      successUrl: `${window.location.origin}/${successUrl}`,
      cancelUrl: `${window.location.origin}/${cancelUrl}`,
      clientReferenceId: userId
    });

    if (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="space-y-2">
        <p>Invest now!</p>
      <div className="flex flex-col gap-4">
        {products.map(product => (
          <button
            key={product.id}
            onClick={() => handleProductSelect(product.priceId)}
            className={`
              px-3 py-2 text-sm font-medium rounded-lg transition-all
              ${selectedPrice === product.priceId 
                ? 'bg-blue-600 text-white ring-2 ring-blue-600 ring-offset-2' 
                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }
            `}
          >
            {product.price.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}
          </button>
        ))}
      </div>

      {selectedPrice && (
        <button
          onClick={handleCheckout}
          className="w-full px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Stake Now
        </button>
      )}
    </div>
  );
};

export const CompactCustomProductDisplay: React.FC<{
  logged: boolean;
  userId: string | undefined;
  poolAddress: string;
}> = ({ logged, userId, poolAddress }) => {
  if (logged === false || userId === undefined) {
    return <CompactStake />;
  }

  return (
    <CompactProductDisplay
      logged={logged}
      successUrl="payment/success"
      cancelUrl="payment/error"
      userId={userId}
      poolAddress={poolAddress}
    />
  );
};




export const ProductDisplay: React.FC<{logged: boolean; successUrl:string; cancelUrl:string, userId?: string; poolAddress: string;}> = ({logged, successUrl,cancelUrl, userId, poolAddress}) => {

    const [products, setProducts] = useState<Product[]>([]);
    const [selectedPrice, setSelectedPrice] = useState<string>("");
    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {

        if (poolAddress === process.env.REACT_APP_ADDRESS_PRISK) {
        setProducts([
            {
                id: "bronce",
                name: "Bronce",
                description: "Bronce Stake",
                price: 499.00,
                priceId: "price_1PdwtxL2vpc5cVKi4V26AqiN"
            },
            {
                id: "silver",
                name: "Silver",
                description: "Silver Stake",
                price: 999.00,
                priceId: "price_1PdwkEL2vpc5cVKiiYDcdq0d"
            },
            {
                id: "gold",
                name: "Gold",
                description: "Gold Stake",
                price: 25000.00,
                priceId: "price_1PdwklL2vpc5cVKijHKv5Vmv"
            },
            // Add more products as needed
        ]);}


        if (poolAddress === process.env.REACT_APP_ADDRESS_PGOLD) {
            setProducts([   
                {
                    id: "starter",
                    name: "Starter",
                    description: "Starter Stake",
                    price: 249.99,
                    priceId: "price_1PfZhyL2vpc5cVKiOOjk0BFN"
                },
                {
                    id: "pro",
                    name: "Pro",
                    description: "Pro Stake",
                    price: 749.99,
                    priceId: "price_1PdwkEL2vpc5cVKiiYDcdq0d"
                },
                {
                    id: "high",
                    name: "high",
                    description: "high Stakes",
                    price: 19999.99,
                    priceId: "price_1PfZhYL2vpc5cVKitoFaC9ip"
                },
                // Add more products as needed
            ]);}

        const initializeStripe = async () => {
            if (!PUBLISHABLE_KEY) return;   
            const stripeInstance = await loadStripe(PUBLISHABLE_KEY);
            setStripe(stripeInstance);
        };
        initializeStripe();
    }, []);

    const handleProductSelect = (priceId: string) => {
        setSelectedPrice(priceId);
    };

    const handleCheckout = async () => {
        if (!stripe || !selectedPrice) return;

        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: selectedPrice, quantity: 1 }],
            mode: 'payment',
            successUrl: `${window.location.origin}/${successUrl}`,
            cancelUrl: `${window.location.origin}/${cancelUrl}`,
            clientReferenceId: userId
        });

        if (error) {
            console.error('Error:', error);
            // Handle the error (e.g., show an error message to the user)
        }
    };

    return (
        <div className={`my-4 lg:mt-0 lg:w-full lg:flex-shrink-0 text-left`}>
              <div className="rounded-2xl bg-gray-50 py-6 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
                <div className="mx-auto px-8">
                  <div>
                    <h3 className="text-base font-semibold text-gray-600">
                        Invest now and get the best RISK-APR
                    </h3>
                    <div className="flex flex-col md:flex-row">
                    {products.map(product => (
                        <div key={product.id} className="mb-2 p-4 rounded grow text-center items-center">
                            <p>{product.description}</p>
                            <button 
                                onClick={() => handleProductSelect(product.priceId)}
                                className={`px-4 py-2 ${selectedPrice === product.priceId ? 'bg-indigo-600 text-white' : 'bg-gray-200'} rounded`}
                            >
                                {selectedPrice === product.priceId ? 
                                    <span>CUSD
                                        <span className="mx-1">
                                            {(product.price).toFixed(2)}
                                        </span>
                                    </span>
                                    :
                                    <span>CUSD
                                        <span className="mx-1">
                                            {(product.price).toFixed(2)}
                                        </span>
                                    </span>}
                            </button>
                        </div>
                    ))}
                    </div>
                    <button 
                        onClick={handleCheckout} 
                        disabled={!selectedPrice}
                        className={`${!selectedPrice && "hidden "} mt-2 mx-auto block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300`}
                    >
                        Invest Now!
                    </button>
        </div>
        </div>
        </div>
        </div>

    );
};


const CustomProductDisplay: React.FC<{logged: boolean, userId: string | undefined; poolAddress: string;}> = ({logged, userId, poolAddress}) => {

    if (logged === false || userId === undefined)  return <Stake/>
    
    return (<ProductDisplay 
        logged={logged}
        successUrl="payment/success"
        cancelUrl="payment/error"
        userId={userId}
        poolAddress={poolAddress}
    />)

}

