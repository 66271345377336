import React, { useState, useContext } from "react";

import Pool from "./Pool";
import { PoolDto } from "../../services/pools/poolTypes";
import { UserStateCardProps } from "../auth/UserState";
import { UserState } from "../../services/users/userSlice";

const PoolPanel: React.FC<{ pool: PoolDto; userState: UserState }> = ({ pool, userState }) => {
  return (
    <Pool
      pool={pool}
      poolContract={{}}
      userState={userState}
    />
  );
};

export default PoolPanel;
