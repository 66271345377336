import React from "react"
import VerificationButton from "../../components/elements/StartVerificationButton";
import UserWallets from "./UserWallet";
import MailAddress from "../commons/MailAddress";

import { MailIcon } from "lucide-react";

export interface UserStateCardProps {
  userState: {
    user: {
      email: string;
      status: string;
    };
  };
  logout: () => void;
}

const UserStateCard: React.FC<UserStateCardProps> = ({ userState, logout }) => {
  return (
    <div className="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-200 mb-8">
      {/* Header Section */}
      <div className="border-b border-gray-200 bg-gray-50 px-6 py-4">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Your Account</h2>
          <button
            onClick={logout}
            className="rounded-lg bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-gray-300 transition-colors hover:bg-gray-50"
          >
            Log Out
          </button>
        </div>
      </div>

      {/* Content Section */}
      <div className="space-y-6 px-6 py-4">
        {/* Email Display */}
        <div className="flex items-center space-x-3">
          <MailIcon className="h-5 w-5 text-gray-400" />
          <div className="space-y-1">
            <p className="text-sm text-gray-500">Email address</p>
            <p className="font-medium text-gray-900">{userState.user.email}</p>
          </div>
        </div>

        {/* Verification Status */}
        {userState.user.status === "unverified" && (
          <div className="rounded-lg border border-red-200 bg-red-50 p-4">
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-red-800">
                  Identity Verification Required
                </h3>
                <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                  Unverified
                </span>
              </div>
              <p className="text-sm text-red-700">
                You need to verify your identity to invest on Cardefier.
              </p>
              <VerificationButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserStateCard