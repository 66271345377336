// src/components/AppRoutes.tsx

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import Main from '../pages/Main';
import Register from '../pages/auth/Register';
import Login from '../pages/auth/Login';
import UserProfile from '../pages/auth/UserProfile';
import UserCollect from '../pages/auth/UserCollect';
import PoolCars from '../pages/pool/PoolCars';
import CarLoanView from '../pages/cars/CarLoanView';
import Admin from '../pages/admin/Admin';
import DataShow from '../pages/datashow';
import StripePricingTable from '../pages/stripecheckout/PricingTable';
import withAuth from '../components/elements/withAuth';
import PaymentSuccess from '../pages/stake/payment/PaymentSuccess';
import PaymentError from '../pages/stake/payment/PaymentError';
import LoadingSpinner from '../components/elements/loading-spinner';

const ProtectedUserProfile = withAuth(UserProfile);
const ProtectedUserCollect = withAuth(UserCollect);
const ProtectedPoolCars = withAuth(PoolCars);

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { userState, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner/>
  }

  if (!userState.user) {
    return <Navigate to="/login" replace />;
  }
  
  return <>{children}</>;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Login />} />
      <Route path="/pricingtable" element={<StripePricingTable />} />

      <Route path='/payment/success' element={<PaymentSuccess />} />
      <Route path='/payment/error' element={<PaymentError />} />
      

      <Route path="/user_profile" element={<ProtectedUserProfile />} />
      <Route path='/user_collect' element={<ProtectedUserCollect />} />
 

      <Route path="/pool/:poolAddress" element={<PoolCars />} />
      <Route path="/carloan/state/:carLoanAddress" element={<CarLoanView />} />
      <Route path="/admin" element={ <Admin />} />
      <Route path="/datashow" element={<DataShow />} />
    </Routes>
  );
};

export default AppRoutes;