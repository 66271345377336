import React from 'react';

type Partner = {
  name: string;
  role: string;
  logo: string;
};

const PartnerCard: React.FC<Partner> = ({ name, role, logo }) => (
  <div className="text-center">
    <figure className="inline-block">
      <span className="flex items-center justify-center h-36 w-full mb-4">
        <img 
          src={logo} 
          alt={name} 
          className="max-w-[80%] max-h-full object-contain"
        />
      </span>
      <figcaption>
        <div className="font-medium text-gray-900">{name}</div>
        <div className="text-sm text-gray-500">{role}</div>
      </figcaption>
    </figure>
  </div>
);

const StrategicPartners = () => {
  const partners: Partner[] = [
    {
      name: "Super Car Miami",
      role: "Car dealer",
      logo: "/img/partners/01.jpg"
    },
    {
      name: "FYBA",
      role: "Reinsurance",
      logo: "/img/partners/02.jpg"
    },
    {
      name: "Line Capital",
      role: "Sales Finance",
      logo: "/img/partners/03.jpg"
    },
    {
      name: "Brio Management Group",
      role: "Portfolio Mgmt",
      logo: "/img/partners/04.jpg"
    },
    {
      name: "Lenderin",
      role: "Warranty & Gap",
      logo: "/img/partners/05.jpg"
    },
    {
      name: "Binance",
      role: "Blockchain Provider",
      logo: "/img/partners/06.jpg"
    }
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-blue-600 text-center mb-16">
          Strategic Partners
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
          {partners.map((partner, index) => (
            <PartnerCard key={index} {...partner} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StrategicPartners;