import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from '../../context/auth-context';
import { AssetName } from '../commons/BlockAddress';
import BlockMoney from '../commons/BlockMoney';
import { CompactProductDisplay, ProductDisplay } from '../stripecheckout/CustomPrices';
import LoadingSpinner from '../../components/elements/loading-spinner';
import PoolStakeEvents from './PoolStakeEvents';
import SideBar from '../experience/SideBar';
import Footer from '../experience/footer';
import DemoLegend from '../experience/demo';
import { getPoolStakeEvents, getPool, getUserPoolState } from '../../services/pools/poolRest';
import { getCarLoansList } from '../../services/cars/carsRest';

const PoolMetric = ({ label, value }: { label: string; value: string }) => (
  <div className="px-4 py-3 bg-gray-50 rounded-lg">
    <div className="text-sm text-gray-500">{label}</div>
    <BlockMoney type="address" money={value} tip="" />
  </div>
);

const CarCard = ({ car }: { car: any }) => (
  <div className="bg-white shadow-lg rounded-xl overflow-hidden transition-all hover:shadow-xl">
    <Link to={`/carloan/state/${car.carloan}`} className="block">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-64 h-48">
          <img
            src={car?.images[0]}
            alt={`${car.brand} ${car.model}`}
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="flex-1 p-6">
          <div className="flex justify-between items-start mb-4">
            <AssetName name={`${car.brand} ${car.model} ${car.year}`} />
            <div className="flex items-center space-x-2">
              <div className="h-2.5 w-2.5 rounded-full bg-emerald-500" />
              <span className="text-sm text-gray-500">Performing</span>
            </div>
          </div>
          
          <BlockMoney
            type="money"
            money={car.principalRemaining}
            tip="Remaining Balance:"
          />
        </div>
      </div>
    </Link>
  </div>
);

const PoolMetrics = ({ poolDto }: { poolDto: any }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
    <PoolMetric label="Available Cash" value={poolDto?.cash} />
    <PoolMetric label="Total Gained" value={poolDto?.gained} />
    <PoolMetric label="Total Staked" value={poolDto?.staked} />
    <PoolMetric label="Principal Remaining" value={poolDto?.principalRemaining} />
  </div>
);

const PoolCars = () => {
  const [open, setOpen] = React.useState(false);
  const { userState } = useAuth();
  const { poolAddress } = useParams();

  if (!poolAddress) throw new Error("No poolAddress");

  const { data: stakingEvents, isLoading: stakingEventsLoading } = useQuery(
    ['poolStakeEvents', poolAddress],
    () => getPoolStakeEvents(poolAddress)
  );

  const { data: carList, isLoading: carListLoading } = useQuery(
    ['carLoansList', poolAddress],
    () => getCarLoansList(poolAddress)
  );

  const { data: poolDto } = useQuery(
    ['poolDto', poolAddress],
    () => getPool(poolAddress)
  );

  if (stakingEventsLoading || carListLoading) return <LoadingSpinner />;

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>

      <main className="pt-20 pb-16">
        <div className="container mx-auto px-4">
          <DemoLegend />
          
          <div className="max-w-4xl mx-auto space-y-8">
            {/* Pool Header */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h1 className="text-2xl font-bold text-gray-900 mb-6">
                {poolDto?.name}
              </h1>

              <ProductDisplay
                logged={Boolean(userState?.user?.id)}
                poolAddress={poolAddress}
                userId={userState?.user?.id}
                successUrl="/success"
                cancelUrl="/cancel"
              />
            </div>

            {/* Pool Metrics */}
            {poolDto && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-6">
                  Pool Analytics
                </h2>
                <PoolMetrics poolDto={poolDto} />
              </div>
            )}

            {/* Car List */}
            <div className="space-y-6">
              <h2 className="text-xl font-bold text-gray-900">
                Available Car Loans
              </h2>
              
              <div className="space-y-4">
                {carList?.map((car) => (
                  <CarCard key={car.carloan} car={car} />
                ))}
              </div>
            </div>

            {/* Staking Events */}
            <PoolStakeEvents stakingEvents={stakingEvents || []} />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PoolCars;