import React from 'react';

type StepProps = {
  image: string;
  title: string;
  description: string;
};

const Step: React.FC<StepProps> = ({ image, title, description }) => (
  <div className="flex flex-col items-center">
    <figure className="text-center mb-4">
      <img 
        src={image} 
        alt={title} 
        className="w-full max-w-xs mx-auto mb-4 transform transition-transform duration-300 hover:scale-105"
      />
      <figcaption className="text-gray-700 text-base leading-relaxed">
        <strong className="block text-gray-800">{title}</strong>
        {description}
      </figcaption>
    </figure>
  </div>
);

const HowItWorks = () => {
  const steps = [
    {
      image: "/img/how-it-works/illustration_01.jpg",
      title: "Car dealer sells a car",
      description: "to be financed"
    },
    {   
      image: "/img/how-it-works/illustration_02.jpg",
      title: "Loan is deployed",
      description: "on a smart contract to the blockchain"
    },
    {
      image: "/img/how-it-works/illustration_03.jpg",
      title: "Car Defiers deposit crypto",
      description: "to finance loans and yield interests"
    },
    {
      image: "/img/how-it-works/illustration_04.jpg",
      title: "Investors receive",
      description: "earnings on a daily basis"
    }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h3 className="text-3xl font-bold text-center mb-16">
          How it works:
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <Step {...step} />
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-1/4 right-0 transform translate-x-1/2">
                  <svg className="w-8 h-8 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;